body {
  margin: 0;
  background-color: #f5f5f5;
  font-family: Roboto, sans-serif !important;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}

html,
body {
  height: 100%;
}


.ql-container {
    font-size: inherit !important;
}
.ql-container.ql-snow {
    border: none !important;
}
.ql-toolbar.ql-snow {
    border: none !important;
    text-align: center !important;
    width: 308px !important;
    margin: 4px auto 0px auto !important;
}

.ql-editor {
  padding: 0px !important;
  line-height: inherit;
  font-size: inherit;
}

.ql-editor .ql-size-huge {
  font-size: 2em !important;
  line-height: 1em !important;
}
.ql-editor .ql-size-large {
  font-size: 1.5em !important;
  line-height: 1em !important;
}

.commit-iphone10-fix {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}

.commitflex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.commitflexvertical {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.commitinlineflex {
  display: -webkit-inline--flex !important;
  display: inline-flex !important;
}

.commitflexwrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
}

main {
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

.commitcenteralign {
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

/* Remove huge X from input fields on IE11 */
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
